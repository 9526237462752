<script>
import simplebar from "simplebar-vue";
import Layout from "../../layouts/main";
import UserService from '@/services/user'

export default {
  components: {
    Layout,
    simplebar,
  },
  mounted() {
    this.contactIdParam = this.$route.params.contactId;

    this.load();
  },
  data() {
    return {
      title: 'Atendimento',
      contacts: [],
      messages: null,
      selectedContact: {},
      messageText: '',
      sending: false,
      submitted: false,
      form: {
        message: ""
      },
      contactIdParam: '',
    }
  },
  methods: {
    load() {
      let loader = this.$loading.show();

      UserService.getChatMessagesByContact().then(async res => {
        this.contacts = res.data;

        if (this.contacts.length > 0) {
          this.selectContact(this.contacts[0]);
        }

        if (this.contactIdParam) {
          const findContact = this.contacts.find(item => item._id === this.contactIdParam);

          if (findContact) {
            this.selectContact(findContact);
          } else {
            const newMessage = await UserService.newMessage(this.contactIdParam);
            this.contacts.unshift(newMessage.data);

            this.selectContact(this.contacts[0]);
          }
        }

        loader.hide();
      }, () => {
        loader.hide();
      })
    },
    selectContact(contact) {
      if (this.selectedContact._id === contact._id) return;

      let loader = this.$loading.show();

      UserService.getChatMessages(contact._id).then(res => {
        // eslint-disable-next-line no-console
        console.log(res);

        this.selectedContact = contact;
        this.messages = res.data;

        loader.hide();

        setTimeout(() => {
          var element = document.getElementById("messages");
          element.scrollTop = element.scrollHeight;
        }, 100);
      }, () => {
        loader.hide();
      })
    },
    sendMessage() {
      if (!this.messageText) return;

      this.sending = true;

      UserService.sendChatMessage(this.messageText, this.selectedContact._id).then(res => {
        this.messages = res.data;
        this.messageText = '';

        this.sending = false;
      }, () => {
        this.sending = false;
        this.toast.error('Ocorreu um erro ao enviar a mensagem')
      })
    },
  },
}
</script>
<template>
  <Layout>
    <div class="d-lg-flex">
      <div class="chat-leftsidebar mr-lg-4">
        <!-- <div class="search-box chat-search-box py-4">
          <div class="position-relative">
            <input type="text" class="form-control" placeholder="Localizar contato..." />
            <i class="bx bx-search-alt search-icon"></i>
          </div>
        </div> -->

        <div class="chat-leftsidebar-nav" v-if="contacts && contacts.length > 0">
          <!-- <b-tabs pills fill content-class="my-4">
            <b-tab title="Tab 1" active> -->
              <b-card-text>
                <div>
                  <h5 class="font-size-14 mb-3">Contatos</h5>
                  <simplebar style="max-height: 410px;">
                    <ul class="list-unstyled chat-list">
                      <li
                        class
                        v-for="contact of contacts"
                        :key="contact.id"
                        @click="selectContact(contact)"
                        :class="{'active': selectedContact._id == contact._id}"
                      >
                        <a href="javascript: void(0);">
                          <div class="media">
                            <!-- <div class="align-self-center mr-3">
                              <i :class="`mdi mdi-circle text-${data.color} font-size-10`"></i>
                            </div>
                            <div class="align-self-center mr-3" v-if="data.image">
                              <img :src="`${data.image}`" class="rounded-circle avatar-xs" alt />
                            </div> -->
                            <div class="avatar-xs align-self-center mr-3">
                              <span
                                class="avatar-title rounded-circle bg-soft-primary text-primary"
                              >{{contact.letter}}</span>
                            </div>

                            <div class="media-body overflow-hidden">
                              <h5 class="text-truncate font-size-14 mb-1">{{contact.name}}</h5>
                              <p class="text-truncate mb-0">{{contact.lastText}}</p>
                            </div>
                            <div class="font-size-11">{{ contact.lastMessage | moment("from", "now") }}</div>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </simplebar>
                </div>
              </b-card-text>
            <!-- </b-tab>
          </b-tabs> -->
        </div>
      </div>
      <div class="w-100 user-chat" v-if="messages && selectedContact">
        <div class="card">
          <div class="p-4 border-bottom">
            <div class="row">
              <div class="col-md-4 col-9">
                <h5 class="font-size-15 mb-1">{{selectedContact.name}}</h5>
              </div>
            </div>
          </div>

          <div class="chat-users">
            <div class="chat-conversation p-3">
              <simplebar style="max-height: 470px;">
                <ul class="list-unstyled" id="messages">
                  <li
                    v-for="message of messages"
                    :key="message._id"
                    :class="{ 'right': (`${message.from}` === 'me') }"
                  >
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <!-- <div class="conversation-name">{{ data.name }}</div> -->
                        <p>{{ message.text }}</p>
                        <p class="chat-time mb-0">
                          <i class="bx bx-time-five align-middle mr-1"></i>
                          {{message.date | moment("from", "now")}}
                        </p>
                      </div>
                    </div>
                  </li>
                </ul>
              </simplebar>
            </div>
            <div class="p-3 chat-input-section">
              <form @submit.prevent="sendMessage" class="row">
                <div class="col">
                  <div class="position-relative">
                    <input
                      type="text"
                      v-model="messageText"
                      class="form-control chat-input"
                      placeholder="Digite sua mensagem..."
                      :class="{ 'is-invalid': submitted && $v.form.message.$error }"
                    />
                    <div v-if="submitted && $v.form.message.$error" class="invalid-feedback">
                      <span v-if="!$v.form.message.required">This value is required.</span>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <button type="submit" class="btn btn-primary btn-rounded chat-send w-md" v-if="!sending">
                    <span class="d-none d-sm-inline-block mr-2">Enviar</span>
                    <i class="mdi mdi-send"></i>
                  </button>
                  <b-spinner v-if="sending" variant="primary" class="m-0" role="status"></b-spinner>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">{{title}}</h4>
        </div>
      </div>
    </div>

    <div class="d-lg-flex">
      <div class="chat-leftsidebar mr-lg-4" v-if="contacts && contacts.length > 0">
        <div class="">
          <div class="search-box chat-search-box">
            <div class="position-relative">
              <input type="text" class="form-control" placeholder="Localizar contato...">
              <i class="bx bx-search-alt search-icon"></i>
            </div>
          </div>

          <div class="chat-leftsidebar-nav">
            <div class="tab-content py-4">
              <div class="tab-pane show active" id="chat">
                <div>
                  <h5 class="font-size-14 mb-3">Contatos</h5>
                  <ul class="list-unstyled chat-list" data-simplebar style="max-height: 410px;">
                    <li :class="{'active': contact._id === selectedContact._id}" v-for="contact in contacts" :key="contact._id">
                      <a href="#" @click="selectContact(contact)">
                        <div class="media">
                          <div class="avatar-xs align-self-center mr-3">
                            <span class="avatar-title rounded-circle bg-soft-primary text-primary">
                              {{contact.letter}}
                            </span>
                          </div>

                          <div class="media-body overflow-hidden">
                            <h5 class="text-truncate font-size-14 mb-1">{{contact.name}}</h5>
                            <p class="text-truncate mb-0">{{contact.lastText}}</p>
                          </div>
                          <div class="font-size-11">{{ contact.lastMessage | moment("from", "now") }}</div>
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 user-chat" v-if="messages && messages.length > 0">
        <div class="card">
          <div class="p-4 border-bottom ">
            <div class="row">
              <div class="col-md-4 col-9">
                <h5 class="font-size-15 mb-1">{{selectedContact.name}}</h5>
              </div>
            </div>
          </div>

          <div>
            <div class="chat-conversation p-3">
              <ul class="list-unstyled" data-simplebar style="max-height: 470px;">
           
                <div v-for="message in messages" :key="message._id">
                  <li :class="{'right': message.from === 'me'}">
                    <div class="conversation-list">
                      <div class="ctext-wrap">
                        <p>
                          {{message.text}}
                        </p>

                        <p class="chat-time mb-0">{{message.date | moment("from", "now")}}</p>
                      </div>
                    </div>
                  </li>
                </div>
              </ul>
            </div>
            <div class="p-3 chat-input-section">
              <div class="row">
                <form @submit.prevent="sendMessage" class="align-items-center">
                  <div class="col">
                    <div class="position-relative">
                      <input type="text" class="form-control chat-input" placeholder="Digite a mensagem..." v-model="messageText">
                    </div>
                  </div>
                  <div class="col-auto">
                    <button :disabled="sending" type="submit" class="btn btn-primary btn-rounded chat-send w-md waves-effect waves-light"><span class="d-none d-sm-inline-block mr-2">Enviar</span> <i class="mdi mdi-send"></i></button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </Layout>
</template>

<style lang="scss">
  #messages {
    max-height: 400px;
    overflow-y: auto;
  }
</style>